module.exports ={
  "propertyService": "https://f48l75gek5.execute-api.us-east-1.amazonaws.com/test2",
  "domusoApiService": "https://1fnbt7wo3f.execute-api.us-east-1.amazonaws.com/test2",
  "appService": "https://domusograilsweb-test2.devmuso.com",
  "reservationService": "https://lc8yp9k5u5.execute-api.us-east-1.amazonaws.com/test2",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://dc2nqt6zzg.execute-api.us-east-1.amazonaws.com/test2",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-test2.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-test2.devmuso.com",
  "maintenanceUrl": "https://dc2nqt6zzg.execute-api.us-east-1.amazonaws.com/test2/maintenance",
  "env": "test2"
}